import React, { useState } from "react"
import styled from "styled-components"
import LoginLayout from "layouts/LoginLayout"
import {
  chevronBackOutline,
  lockClosed,
  personCircleOutline,
} from "ionicons/icons"
import Button from "components/Button"
import Logo from "components/Logo"

import { useLoginMutation } from "services/login"
import { useAuthToken } from "services/authToken"
import { navigate } from "gatsby"
import { useUserQuery } from "services/getUserData"
import Input from "components/Input"
import Link from "components/Link"
import Alert from "components/Alert/Alert"
import Spinner from "components/Spinner"
import { useAppApolloClient } from "services/client"
import { ApolloProvider } from "@apollo/client"
import { useLocation } from "@reach/router"
import config from "config/config"
import { Helmet } from "react-helmet"

const LinkWrapper = styled.div`
  margin-bottom: 18px;
`

const Form = styled.form`
  display: block;
  width: 300px;
  max-width: calc(100vw - 48px);
`

const StyledLogo = styled(Logo)`
  width: 200px;

  ${props => props.theme.media.md} {
    width: 220px;
  }
`

const LinksWrapper = styled.div`
  margin-top: 32px;
  text-align: center;
`

const LoginContent = () => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [login] = useLoginMutation()
  const [authToken, setAuthToken, removeAuthToken] = useAuthToken()
  const [loginError, setLoginError] = useState(false)
  const [loginErrorMessage, setLoginErrorMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const isReset = urlParams.get("reset") === "true"

  const userData = useUserQuery().data

  if (authToken && userData) {
    navigate("/konto")
  }

  const handleLoginChange = (e: React.FormEvent<HTMLInputElement>) => {
    setLoginError(false)
    setUsername(e.currentTarget.value)
  }

  const handlePasswordChange = (e: React.FormEvent<HTMLInputElement>) => {
    setLoginError(false)
    setPassword(e.currentTarget.value)
  }

  const handleSubmit = (e: React.MouseEvent) => {
    e.preventDefault()
    setLoading(true)
    removeAuthToken()
    if (!(username && password)) {
      setLoginErrorMessage("Wypełnij wszystkie wymagane pola")
      setLoginError(true)
      setLoading(false)
      return
    }
    login(username, password)
      .then(({ data }) => {
        setAuthToken(data.login.jwt)
        navigate("/konto")
      })
      .catch(e => {
        setLoginErrorMessage("Podano błędny email lub hasło")
        setLoginError(true)
        setLoading(false)
      })
  }

  return (
    <>
      <Helmet>
        <title>Zaloguj | Wspólnota 120</title>
        <meta name={"og:title"} content={`Zaloguj | Wspólnota 120`} />
        <meta
          name={"description"}
          content={"Zaloguj się do konta uczestnika Wspólnoty 120."}
        />
        <meta
          name={"og:description"}
          content={"Zaloguj się do konta uczestnika Wspólnoty 120."}
        />
      </Helmet>
      <LoginLayout>
        <StyledLogo />

        <Form>
          {isReset && (
            <div style={{ marginTop: "24px" }}>
              <Alert isActive={true} success>
                Hasło zostało zmienione. Możesz się teraz zalogować
              </Alert>
            </div>
          )}
          <Input
            type={"text"}
            label={"Email"}
            icon={personCircleOutline}
            value={username}
            onChange={handleLoginChange}
            block
            required
          />
          <Input
            type={"password"}
            label={"Hasło"}
            icon={lockClosed}
            value={password}
            onChange={handlePasswordChange}
            block
            required
          />

          <div style={{ marginTop: "32px" }}>
            <Button type={"submit"} block onClick={handleSubmit}>
              {loading ? <Spinner size={24} /> : "Zaloguj"}
            </Button>
          </div>
          <div style={{ marginTop: "16px" }}>
            <Alert isActive={loginError} error>
              {loginErrorMessage}
            </Alert>
          </div>
        </Form>
        <LinksWrapper>
          <LinkWrapper>
            <Link to={"/forgot"}>Zapomniałeś hasła?</Link>
          </LinkWrapper>
          <LinkWrapper>
            <Link to={"/"} icon={chevronBackOutline}>
              Powrót do strony głównej
            </Link>
          </LinkWrapper>
        </LinksWrapper>
      </LoginLayout>
    </>
  )
}

const Zaloguj = () => {
  const client = useAppApolloClient()

  return (
    <>
      <ApolloProvider client={client}>
        <LoginContent />
      </ApolloProvider>
    </>
  )
}

export default Zaloguj
