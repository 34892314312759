import { gql, useMutation } from "@apollo/client"

export const loginMutationGQL = gql`
  mutation login($login: String!, $password: String!) {
    login(
      input: { identifier: $login, password: $password, provider: "local" }
    ) {
      jwt
    }
  }
`

type LOGIN_MUTATION_TYPE = {
  login: {
    jwt: string
  }
}

export const useLoginMutation = () => {
  const [mutation, mutationResults] = useMutation<LOGIN_MUTATION_TYPE>(
    loginMutationGQL
  )

  //we have rewritten the function to have a cleaner interface
  const login = (user: string, password: string) => {
    return mutation({
      variables: {
        login: user,
        password: password,
      },
    })
  }
  return [login, mutationResults]
}
